.order-action {
    display: flex;
    flex-direction: row;
}

.custom-create {
    color: #3f51b5;
}

.description {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.46429em;
}