.upload-csv-lbl {
    font-size: 0.8125rem;
}

.upload-csv-btn {
    color: #3f51b5 !important;
    padding: 5px 8px !important;
    min-width: 64px !important;
    font-size: 0.8125rem !important;
    min-height: 32px !important;
}

.csv-upload-input {
    display: none;
}