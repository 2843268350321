body {
    margin: 0;
    padding: 0;
}

.user-list {
    /* width: max-content; */
}

.title-custom {
    padding: 15px;
    font-size: 1.5rem;
}

.user-list-table span {
    width: auto;
}

.shareBtn {
    width: 150px;
    height: 40px;
    margin: 0 20px 0 0 !important;
    background: green !important;
    color: white !important;
}

.shareBtn.sequence {
    position: unset !important;
    right: 0 !important;
}

@media only screen and (max-width: 1600px) {
    .user-list-table span {
        width: calc(90vw/20);
        word-wrap: break-word;
    }
  }