.custom-create {
    color: #3f51b5 !important;
}

.custom-create .plus {
    font-size: 23px;
    margin-right: 3px;
}

.cancel-btn-action {
    color: #3f51b5 !important;
}