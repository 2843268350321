button, .image-block img {
    cursor: pointer;
}

.seq-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.image-block {
    height: auto;
    overflow: auto;
    width: 28%;
}

.image-block-paper {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.seq-edit .edit-page {
    width: 70% !important;
}

.image-block .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.image-block img:hover {
    opacity: 0.8;
}

.sounds, .images-block {
    margin-top: 10px !important;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
    -moz-transition: visibility 0s, opacity 0.5s linear;
    -ms-transition: visibility 0s, opacity 0.5s linear;
    -o-transition: visibility 0s, opacity 0.5s linear;
}

.sounds label {
    font-size: 1.2rem;
}

ul {
    margin-top: 0px;
    list-style-type: none;
}

.sounds ul li {
    margin-top: 5px;
}

.sounds ul li label {
    font-size: 1rem;
    cursor: pointer;
}

.sounds ul li label:hover {
    color: cadetblue;
}

.sounds.visible, .images-block.visible {
    visibility: visible;
    opacity: 1;
}

.files-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.files-actions button:first-child {
    margin-right: 10px;
}