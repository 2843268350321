.toolbar-custom {
    display: flex;
    justify-content: flex-end;
    background-color: white !important;
}

.toolbar-custom .save-btn, .toolbar-custom .cancel-btn {
    margin-right: 20px;
}
.upload-csv-lbl {
    font-size: 0.8125rem;
}

.upload-csv-btn {
    color: #3f51b5 !important;
    padding: 5px 8px !important;
    min-width: 64px !important;
    font-size: 0.8125rem !important;
    min-height: 32px !important;
}

.csv-upload-input {
    display: none;
}
.custom-create {
    color: #3f51b5 !important;
}

.custom-create .plus {
    font-size: 23px;
    margin-right: 3px;
}

.cancel-btn-action {
    color: #3f51b5 !important;
}
button, .image-block img {
    cursor: pointer;
}

.seq-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.image-block {
    height: auto;
    overflow: auto;
    width: 28%;
}

.image-block-paper {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.seq-edit .edit-page {
    width: 70% !important;
}

.image-block .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.image-block img:hover {
    opacity: 0.8;
}

.sounds, .images-block {
    margin-top: 10px !important;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
    -moz-transition: visibility 0s, opacity 0.5s linear;
    -ms-transition: visibility 0s, opacity 0.5s linear;
    -o-transition: visibility 0s, opacity 0.5s linear;
}

.sounds label {
    font-size: 1.2rem;
}

ul {
    margin-top: 0px;
    list-style-type: none;
}

.sounds ul li {
    margin-top: 5px;
}

.sounds ul li label {
    font-size: 1rem;
    cursor: pointer;
}

.sounds ul li label:hover {
    color: cadetblue;
}

.sounds.visible, .images-block.visible {
    visibility: visible;
    opacity: 1;
}

.files-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.files-actions button:first-child {
    margin-right: 10px;
}
.order-action {
    display: flex;
    flex-direction: row;
}

.custom-create {
    color: #3f51b5;
}

.description {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.46429em;
}
body {
    margin: 0;
    padding: 0;
}

.user-list {
    /* width: max-content; */
}

.title-custom {
    padding: 15px;
    font-size: 1.5rem;
}

.user-list-table span {
    width: auto;
}

.shareBtn {
    width: 150px;
    height: 40px;
    margin: 0 20px 0 0 !important;
    background: green !important;
    color: white !important;
}

.shareBtn.sequence {
    position: unset !important;
    right: 0 !important;
}

@media only screen and (max-width: 1600px) {
    .user-list-table span {
        width: calc(90vw/20);
        word-wrap: break-word;
    }
  }
